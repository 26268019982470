import React from 'react';
import Page from '../components/layout/Page';
import Contact from '../components/layout/contact/Contact';

const ContactPage = () => (
  <Page title="Contact Us">
    <Contact />
  </Page>
);

export default ContactPage;
