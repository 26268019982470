import React from 'react';
import { Row, Col } from 'react-grid-system';
import classnames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../../common/Button';
import styles from './Contact.module.scss';
import { ReactComponent as AddressIcon } from '../../../images/icons/marker.svg';
import { ReactComponent as PhoneIcon } from '../../../images/icons/phone.svg';
import { ReactComponent as WebIcon } from '../../../images/icons/web.svg';
import { ReactComponent as EmailIcon } from '../../../images/icons/email.svg';
// import { ReactComponent as CaptchaIcon } from '../../../images/icons/recaptcha.svg';
// import { ReactComponent as OkIcon } from '../../../images/icons/ok.svg';
// import { ReactComponent as CancelIcon } from '../../../images/icons/cancel.svg';
import {
  contactAddress,
  contactPhone,
  contactPhoneLink,
  contactOnlineServiceUrl,
  contactOnlineServiceEmail,
  recaptchaSitekey,
} from '../../../config/settings';
import ContactImgUrl from '../../../images/contact/contact.svg';

const Contact = () => {
  return (
    <section className={styles.sectionContact}>
      <div className={styles.contactHeading}>
        <h1>
          <strong>Contact</strong> Us
        </h1>
        <div className={styles.contactDesc}>Feel free to drop us a line.</div>
      </div>
      <div className={styles.contactBody}>
        <div className={styles.contactImg}>
          <img src={ContactImgUrl} alt="" />
        </div>
        <div className={styles.contactFormBlock}>
          <h2>Send us a message</h2>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
            className={styles.contactForm}
          >
            <input type="hidden" name="form-name" value="contact" />
            <Row gutterWidth={20}>
              <Col md={6}>
                <div className={classnames(styles.formGroup, styles.required)}>
                  <label htmlFor="contactFirstName">
                    <span>First name</span>
                    <input
                      id="contactFirstName"
                      type="text"
                      name="firstName"
                      className={styles.formControl}
                      placeholder="Enter your name"
                      required
                    />
                  </label>
                </div>
              </Col>
              <Col md={6}>
                <div className={classnames(styles.formGroup, styles.required)}>
                  <label htmlFor="contactLastName">
                    <span>Last name</span>
                    <input
                      id="contactLastName"
                      type="text"
                      name="lastName"
                      className={styles.formControl}
                      placeholder="Enter your last name"
                      required
                    />
                  </label>
                </div>
              </Col>
            </Row>
            <Row gutterWidth={20}>
              <Col md={6}>
                <div className={classnames(styles.formGroup, styles.required)}>
                  <label htmlFor="contactEmail">
                    <span>Email address</span>
                    <input
                      id="contactEmail"
                      type="email"
                      name="email"
                      className={styles.formControl}
                      placeholder="Enter your email"
                      required
                    />
                  </label>
                </div>
              </Col>
              <Col md={6}>
                <div className={styles.formGroup}>
                  <label htmlFor="contactPhone">
                    <span>Phone number (optional)</span>
                    <input
                      id="contactPhone"
                      type="text"
                      name="phone"
                      className={styles.formControl}
                      placeholder="Enter your phone number"
                    />
                  </label>
                </div>
              </Col>
            </Row>
            <div className={styles.formGroup}>
              <label htmlFor="contactDesc">
                <span>Message</span>
                <textarea
                  id="contactDesc"
                  name="message"
                  className={styles.formControl}
                  placeholder="Enter your message"
                  required
                />
              </label>
            </div>

            <div className={styles.formCheckbox}>
              <label htmlFor="contactPrivacy">
                <input id="contactPrivacy" type="checkbox" required />
                <span>
                  By submitting this form I confirm that I have read and understood the Privacy and that the Boost sales
                  team can reach out to me by using the information I provided above.
                </span>
              </label>
            </div>

            <div className={styles.formCheckbox}>
              <ReCAPTCHA sitekey={recaptchaSitekey} />
            </div>

            {/* <div className={styles.formCheckbox}>
              <label className={styles.robotLabel} htmlFor="contactCaptcha">
                <input id="contactCaptcha" type="checkbox" />
                <span>
                  <span>I’m not a robot</span>
                  <CaptchaIcon width={51} className={styles.icon} />
                </span>
              </label>
            </div> */}

            <div className={styles.btns}>
              <Button type="submit" color="secondary">
                Submit
              </Button>
            </div>

            {/* <div className={classnames(styles.contactFormMessage, styles.success)}>
              <span>Submitted successfully</span> <OkIcon width={15} className={styles.icon} />
            </div>
            <div className={classnames(styles.contactFormMessage, styles.error)}>
              <span>An error occured</span> <CancelIcon width={15} className={styles.icon} />
            </div> */}
          </form>
        </div>
        <div className={styles.contactDetails}>
          {contactAddress ? (
            <div className={styles.contactItem}>
              <div className={styles.contactItemHeading}>Address</div>
              <div className={styles.contactItemBody}>
                <p>
                  <AddressIcon width={8} className={styles.icon} />
                  <span>{contactAddress}</span>
                </p>
              </div>
            </div>
          ) : null}

          {contactPhone && contactPhoneLink ? (
            <div className={styles.contactItem}>
              <div className={styles.contactItemHeading}>Phone</div>
              <div className={styles.contactItemBody}>
                <p>
                  <a href={contactPhoneLink}>
                    <PhoneIcon width={10} className={styles.icon} />
                    <span>{contactPhone}</span>
                  </a>
                </p>
              </div>
            </div>
          ) : null}

          <div className={styles.contactItem}>
            <div className={styles.contactItemHeading}>Online services</div>
            <div className={styles.contactItemBody}>
              {contactOnlineServiceUrl ? (
                <p>
                  <a href={contactOnlineServiceUrl}>
                    <WebIcon width={10} className={styles.icon} />
                    <span>{contactOnlineServiceUrl}</span>
                  </a>
                </p>
              ) : null}
              {contactOnlineServiceEmail ? (
                <p>
                  <a href={`mailto:${contactOnlineServiceEmail}`}>
                    <EmailIcon width={10} className={styles.icon} />
                    <span>{contactOnlineServiceEmail}</span>
                  </a>
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
